import * as React from "react";
import * as styles from './ProjectDetails.module.css';
import data from '../../assets/data/data';

export default function ProjectDetails({ type, title }) {
    const [project, setProject] = React.useState([]);

    React.useEffect(() => {
        const parsedTitle = title.replaceAll('-', ' ').toLowerCase();
        const projectDetails = data[type].filter(project => project.title.replaceAll(',','').toLowerCase() === parsedTitle);
        setProject(projectDetails[0]);

        console.log(parsedTitle, projectDetails);
    }, [type, title]);

    return (
        <div className={styles.detailsContainer}>
            {/* <div className={styles.details}>
                <h1>{bookReview?.date}</h1>
                <span className={styles.bookTitle}>{bookReview?.title}</span> - {bookReview?.author}
                <p className={styles.rating}>{bookReview?.rating}</p>
            </div> */}

            {project?.details?.map(e => {
                if (typeof e === "string") {
                    return <p>{e}</p>
                } else if (e.type === "img-lg") {
                    return <img className={styles.imgLg} src={e.url} alt={e.alt} />
                } else if (e.type === "img-sm") {
                    return <div className={styles.imgSmContainer}>
                        {e.images.map(img => {
                            return <img className={styles.imgSm} src={img.url} alt={img.alt} />
                        })}
                    </div>
                }

                return null;
            })}
        </div>
    )
};
