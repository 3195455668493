import * as React from "react";
import * as styles from '../../assets/styles/styles.module.css';
import Header from "../../components/Header/Header";
import Navigation from "../../components/Navigation/Navigation";
import ProjectDetails from "../../components/ProjectDetails/ProjectDetails";
import Footer from "../../components/Footer/Footer";

const BooksPage = ({ title }) => {
  return (
    <main>
      <Header />
      <div className={styles.wrapper}>
        <Navigation />
        <ProjectDetails type="designDetails" title={title} />
      </div>
      <Footer />
    </main>
  )
}

export default BooksPage
